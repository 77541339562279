//
@import "./Color.scss";

//
.about {
  &-title {
    font-size: 2rem;
    margin: 3rem auto 1rem auto;
    text-align: center;
  }
  &-definition {
    font-size: 1.2rem;
    text-align: center;
    margin: 1rem;
  }
  &-content {
    display: block;
    width: 1200px;
    margin: auto auto 3.5rem auto;
    &-img {
      width: 100%;
      height: 500px;
      margin: auto;
      img {
        object-fit: cover;
        object-position: top;
      }
    }
    &-detail {
      margin-top: 1rem;
    }
    .content {
      &-card {
        width: 500px;
        &-title {
          color: $red-color;
          font-size: 1.2rem;
          border-bottom: 1px solid $red-color;
          margin-bottom: 1rem;
        }
        &-detail {
          display: grid;
          grid-template-columns: 150px auto;
          grid-gap: 15px;
        }
        &-img {
          width: 150px;
          height: 100px;
          img {
            object-fit: cover;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1280px) {
  .about {
    &-content {
      width: calc(100% - 80px);
      margin: auto 40px 3.5rem 40px;
      &-img {
        width: 100%;
        height: 400px;
        margin: auto;
        img {
          object-fit: cover;
          object-position: top;
        }
      }
      .content {
        &-card {
          width: 45%;
        }
      }
    }
  }
}

@media screen and (max-width: 780px) {
  .about {
    &-content {
      &-img {
        width: 100%;
        height: 350px;
        margin: auto;
        img {
          object-fit: cover;
          object-position: top;
        }
      }
      .content {
        &-card {
          width: 100%;
          &:nth-of-type(n + 2) {
            margin-top: 2rem;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 580px) {
  .about {
    &-content {
      width: calc(100% - 20px);
      margin: auto 10px 3.5rem 10px;
      &-img {
        width: 100%;
        height: 200px;
        margin: auto;
        img {
          object-fit: cover;
          object-position: top;
        }
      }
    }
  }
}
