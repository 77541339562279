//
@import "./Color.scss";

//
img {
  object-fit: cover !important;
}

.product {
  &-car {
    max-width: 1800px;
    margin-top: 1.5rem;
    margin-right: auto !important;
    margin-left: auto !important;

    &-thumb {
      position: relative;
      display: grid;
      width: 270px;
      height: 310px;
      margin: 1rem auto;
      overflow: hidden;

      &-img {
        display: block;
        width: 100%;
        height: 170px;
      }

      &-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        strong {
          width: 100%;
          font-size: 1.2rem;
          font-weight: 500;
        }

        button {
          border: 1px solid $gray-color;
          width: fit-content;
          margin: auto;
          //   z-index: 99;
        }
      }

      &::before {
        content: "";
        position: absolute;
        right: 0;
        bottom: 0;
        left: 0;
        height: 0;
        background-color: #000000c9;
        transition: all 0.5s;
      }

      &:hover {
        &::before {
          height: 100%;
        }

        .product-car-thumb-content {
          position: absolute;
          height: 100%;
          padding-top: 60px;
          animation: detail 0.5s 1;
          animation-fill-mode: forwards;

          strong {
            font-size: 1.4rem;
            color: $white-color;
            margin-bottom: 2rem;
          }

          p {
            display: flex;
            justify-content: center;
            flex-direction: column;
            color: $red-color;
            font-size: 1.3rem;
          }

          button {
            color: $white-color;
            border: 1px solid $white-color;
            margin-top: 0;

            &:hover {
              color: $black-color;
              background-color: $white-color;
            }
          }
        }
      }
    }
  }
}

@keyframes detail {
  0% {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    bottom: 0;
  }

  100% {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.product_dt {
  &-banner {
    position: relative;
    height: calc(100vh - 112px);

    img {
      object-fit: cover;
      object-position: top;
    }

    button {
      position: absolute;
      top: 50px;
      right: 50px;
      width: 150px;
      font-size: 1.3rem;
      padding: 12px;
      color: $white-color;
      border: 2px solid $white-color;
      border-top-left-radius: 50px;
      border-bottom-right-radius: 50px;
      background-color: #00000059;

      &:hover {
        background-color: $white-color;
      }
    }
  }

  &-car {
    display: block;
    width: 100%;
    height: fit-content;
    color: $white-color;
    padding: 2rem;
    text-align: center;
    background-color: $black-color;

    &-title {
      position: relative;
      width: fit-content;
      margin: 1rem auto;
      font-size: 2.5rem;
      background-color: $black-color;
      z-index: 99;

      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: -510px;
        display: block;
        width: 500px;
        height: 2px;
        background-color: $white-color;
        z-index: 9;
      }

      &::after {
        content: "";
        position: absolute;
        top: 50%;
        right: -510px;
        display: block;
        width: 500px;
        height: 2px;
        background-color: $white-color;
        z-index: 9;
      }
    }

    &-content {
      font-size: 1.2rem;
    }

    &-price {
      font-size: 1.4rem;
      color: $red-color;
    }
  }
}

.feature {
  position: relative;
  display: block;
  width: 100%;
  height: 55vh;
  color: $white-color;
  background: $black-color;
  overflow: hidden;

  .feature-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 60%;
    height: 100%;
    background-color: #00000099;
    background-image: linear-gradient(90deg, $black-color 80%, transparent 100%);
    transform: skew(-20deg);
    z-index: 9;
  }

  .feature-img {
    display: block;
    width: 60%;
    height: 100%;
    margin-left: auto;
  }

  .feature-content {
    position: absolute;
    top: calc(50% - 30px);
    left: 100px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 40%;
    height: 100%;
    text-align: center;
    z-index: 10;

    strong {
      font-size: 2.5rem;
      font-weight: 400 !important;
    }
  }

  &:nth-of-type(2n) {
    .feature-overlay {
      position: absolute;
      left: auto;
      right: 0;
      background-image: linear-gradient(90deg, transparent 0%, $black-color 20%);
    }

    .feature-img {
      display: block;
      width: 60%;
      height: 100%;
      margin-left: 0;
    }

    .feature-content {
      left: auto;
      right: 100px;
    }
  }
}

.description-car {
  display: flex;
  flex-wrap: wrap;
  margin: 1rem;
  justify-content: space-around;
  margin-top: 3rem;
  //color: $white-color;
  //background-color: $black-color;
}

@media screen and (max-width: 1480px) {}

@media screen and (max-width: 1080px) {
  .product_dt-banner {
    position: relative;
    height: auto;
    background-color: $black-color;
  }

  .feature .feature-img {
    display: block;
    width: 70%;
    height: 100%;
    //margin-left: 20%;
  }

  .feature:nth-of-type(2n) .feature-img {
    display: block;
    width: 70%;
    height: 100%;
    //margin-left: -20%;
  }

  .feature {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    color: #fff;
    background: #000;
    overflow: hidden;
  }

  .feature .feature-content strong {
    font-size: 1.5rem;
    font-weight: 800 !important;
    color: $red-color;
  }

  .feature .feature-content {
    position: absolute;
    top: calc(50% - 80px);
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 55%;
    height: 100%;
    text-align: center;
    z-index: 10;
    font-size: 1rem;
  }

  .feature:nth-of-type(2n) .feature-content {
    left: auto;
    right: 10px;
  }

  .feature .feature-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 60%;
    height: 100%;
    background-color: #00000099;
    background-image: linear-gradient(90deg, #000 80%, transparent 100%);
    transform: skew(-20deg);
    z-index: 9;
  }

  .feature:nth-of-type(2n) .feature-overlay {
    position: absolute;
    left: auto;
    right: 0;
    background-image: linear-gradient(90deg, transparent 0%, #000 20%);
    transform: skew(20deg);
  }

  .product_dt-banner button {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    font-size: 1.3rem;
    padding: 12px;
    color: #fff;
    border: 2px solid #fff;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #00000059;
  }

  .product_dt-car-title {
    position: relative;
    width: auto;
    margin: auto;
    font-size: 1.5rem;
    background-color: #000;
    z-index: 1;
  }

  .product_dt-car-title::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: auto;
    height: 2px;
    background-color: #fff;
    z-index: 9;
  }

  .product_dt-car-title::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: auto;
    height: 2px;
    background-color: #fff;
    z-index: 9;
  }
}

@media screen and (max-width: 580px) {
  .product_dt-banner {
    position: relative;
    height: auto;
    background-color: $black-color;
  }

  .feature .feature-img {
    display: block;
    width: 100%;
    height: 100%;
    margin-left: 20%;
  }

  .feature:nth-of-type(2n) .feature-img {
    display: block;
    width: 100%;
    height: 100%;
    margin-left: -20%;
  }

  .feature {
    position: relative;
    display: block;
    width: 100%;
    height: auto;
    color: #fff;
    background: #000;
    overflow: hidden;
  }

  .feature .feature-content strong {
    font-size: 10px;
    font-weight: 800 !important;
    color: $red-color;
  }

  .feature .feature-content {
    position: absolute;
    top: calc(50% - 80px);
    left: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    height: 100%;
    text-align: center;
    z-index: 10;
    font-size: 8px;
  }

  .feature:nth-of-type(2n) .feature-content {
    left: auto;
    right: 10px;
  }

  .feature .feature-overlay {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 70%;
    height: 100%;
    background-color: #00000099;
    background-image: linear-gradient(90deg, #000 80%, transparent 100%);
    transform: skew(-20deg);
    z-index: 9;
  }

  .feature:nth-of-type(2n) .feature-overlay {
    position: absolute;
    left: auto;
    right: 0;
    background-image: linear-gradient(90deg, transparent 0%, #000 20%);
    transform: skew(20deg);
  }

  .product_dt-banner button {
    position: relative;
    top: auto;
    right: auto;
    width: 100%;
    font-size: 1.3rem;
    padding: 12px;
    color: #fff;
    border: 2px solid #fff;
    border-top-left-radius: 50px;
    border-bottom-right-radius: 50px;
    background-color: #00000059;
  }

  .product_dt-car-title {
    position: relative;
    width: auto;
    margin: auto;
    font-size: 1.5rem;
    background-color: #000;
    z-index: 1;
  }

  .product_dt-car-title::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: auto;
    height: 2px;
    background-color: #fff;
    z-index: 9;
  }

  .product_dt-car-title::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    display: block;
    width: auto;
    height: 2px;
    background-color: #fff;
    z-index: 9;
  }
}