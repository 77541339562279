.buttoncar {
  color: white;
  background-color: rgb(3, 3, 3);
  padding: 5px;
  padding-right: 15px;
  padding-left: 15px;
  text-align: center;
  display: inline-table;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;

}

.buttoncar:hover {
  background-image: linear-gradient(#fafafa, #818181);
  color: rgb(0, 0, 0);
}



.banner-car {
  background-color: #000;
  padding: 5rem 0;
  color: #fff;
  text-align: center;
}

.btm-custom {
  color: #fff !important;
  background-color: transparent !important;
  border-color: #f5f5f9 !important;
}

.btm-custom:hover {
  color: rgb(12, 12, 12) !important;
  background-color: white !important;
  border-color: #080808 !important;
}

.img-selactcar {
  display: block;
  width: 100%;
  height: 15rem;
  object-fit: contain;
}

.img-selactcar img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.img-car {
  display: block;
  width: 100%;
  height: 9rem;
  object-fit: contain;
}

.img-car img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}


.banner-car {
  background-color: #000;
  padding: 5rem 0;
  color: #fff;
  text-align: center;
}

.banner-car1 {
  background-color: #000;
  padding: 4rem 0;
  color: #fff;
  text-align: center;
}

.element-body-car {
  background-color: #000000;
  background-image: repeating-linear-gradient(#000, #000 10%, #fff 10%, #fff 100%);

}

.element-body-car1 {
  background-color: #000000;
  background-image: repeating-linear-gradient(#000, #000 15%, #fff 15%, #fff 100%);
}

.container-body-car {
  margin: 0 15% 4rem 15%;

}
.card-des{
  text-align: center;
  word-wrap: break-word;
  min-width: 0;
}
.text-font{
  font-size: 34px;
}
.text-font1{
  font-size: 24px;
}
.btn-outline-dark {
  
  border-color: #000 !important;
  padding: .8rem 4rem !important;
}
@media screen and (max-width: 576px) {
  .btn-outline-dark {
    padding: .1rem 1.5rem !important;
}
  .container-body-car {
    margin: 0 !important;
  }

  .img-car img {
    width: auto;

  }

  .img-car {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 9rem;
  }

  .element-body-car {
    background-color: #fff;
    background-image: unset;
  }

  .img-selactcar {
    display: block;
    width: 100%;
    height: 10rem;
  }

  .img-selactcar img {
    width: auto;

  }

  .element-body-car1 {
    background-color: #fff;
    background-image: unset;
  }
  .text-font{
    font-size: 24px;
  }
  .text-font1{
    font-size: 18px;
  }
}

@media screen and (min-width: 576.1px) {
  .img-car {
    width: 100%;
    height: 12rem;
  }

  .img-selactcar {
    width: 100%;
    height: 13rem;
  }

  
}

@media screen and (max-width: 768.1px) { 
  .element-body-car1 {
    background-color: #fff;
    background-image: unset;
  }
}

@media screen and (max-width: 992px) {
  .container-body-car {
    margin: 0 5% 4rem 5%;
    padding: 2rem;
  }
  
  .img-selactcar {
    display: block;
    width: 100%;
    height: 10rem;
  }

  .img-selactcar img {
    width: auto;

  }
}

@media screen and (max-width: 1024.1px) {
  
  .img-selactcar {
    display: block;
    width: 100%;
    height: 10rem;
  }

  .img-selactcar img {
    width: auto;

  }
}