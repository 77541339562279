//
@import "./Color.scss";

//
.contact {
    display: flex;
    flex-wrap: wrap;
    width: 1060px;
    margin: 50px auto;
    &-title {
        display: block;
        width: 100%;
        font-size: 2.5rem;
        text-align: center;
        margin-bottom: 1rem;
    }
    &-content {
        width: 49%;
        padding-right: 15px;
        &-title {
            font-size: 1.2rem;
            font-weight: 500;
        }
        strong {
            font-weight: 500;
        }
    }
    &-map {
        display: block;
        width: 50%;
        height: auto;
        min-height: 300px;
    }
    ul {
        list-style: none;
        padding-inline-start: 10px;
        margin-bottom: unset;
    }
    iframe {
        width: 100%;
        height: 100%;
    }
}

@media screen and (max-width: 1080px) {
    .contact {
        width: calc(100% - 40px);
        &-content {
            //         display: flex;
            //         flex-wrap: wrap;
            width: 420px;
            //         & > strong {
            //             width: fit-content;
            //             // margin-bottom: 1rem;
            //             margin-right: 1rem;
            //         }
            //         & > p {
            //             width: 48%;
            //             margin-right: 1%;
            //             &:first-of-type {
            //                 width: fit-content;
            //                 margin-top: 0.425rem;
            //             }
            //         }
        }
        &-map {
            width: calc(100% - 420px);
        }
    }
}
@media screen and (max-width: 780px) {
    .contact {
        &-content {
            display: flex;
            flex-wrap: wrap;
            width: 100%;
            & > p {
                width: 100%;
                flex-wrap: wrap;
                & > strong {
                    white-space: nowrap;
                }
            }
        }
        &-map {
            width: 100%;
        }
    }
}
@media screen and (max-width: 580px) {
    .contact {
        &-content > p > span {
            white-space: nowrap;
            padding-left: 10px;
        }
    }
}
