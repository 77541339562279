  .rcornersProAdmin {

    color: white;
    border-radius: 30px 30px 30px 30px;
    background-image: linear-gradient(#070707, #4b4b4b);
    padding: 4px;
    width: 80px;
    height: 35px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }

  .rcornersProAdmin:hover {
    background-image: linear-gradient(#fafafa, #818181);
    color: rgb(0, 0, 0);
  }

  .rcornersProAdmin1 {

    color: white;
    border-radius: 30px 30px 30px 30px;
    background-image: linear-gradient(#070707, #4b4b4b);
    padding: 4px;
    width: 280px;
    height: 35px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }

  .rcornersProAdmin1:hover {
    background-image: linear-gradient(#fafafa, #818181);
    color: rgb(0, 0, 0);
  }

  .rcornersproduct1 {

    color: white;
    border-radius: 30px 30px 30px 30px;
    background-image: linear-gradient(#070707, #4b4b4b);
    padding: 6px;
    width: 160px;
    /* height: 35px; */
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 1rem;

  }

  /* ------------แก้ไขใหม่--------------- */
  .headproduct {
    display: flex;
    padding-top: 2rem;
    border-bottom: 1px solid rgb(0, 0, 0);
  }

  .bt-headproduct>div {
    justify-content: center;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    font-size: 18px;
    font-weight: 600;
  }

  .active .bt-headproduct {
    color: rgb(249, 0, 1);
  }

  .bt-headproduct>div>div:hover {
    color: rgb(249, 0, 1);
  }

  /* กรอกรูปรุ่นรถย่อย */
  .border-car {
    display: flex;
    flex-direction: column;
    width: 150px;
    height: 150px;
    justify-content: center;
    position: absolute;
    top: calc(50% - 60px);
    left: calc(50% - 75px);
    border: 2px dashed #777;
  }

  /* กรอกโปรชัวร์ */
  .border-car1 {
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 80px;
    justify-content: center;
    position: absolute;
    top: calc(50% - 40px);
    left: calc(50% - 130px);
    border: 2px dashed #777;
  }

  /* กรอกรูปเพิ่มรุ่นรถย่อย */
  .border-car2 {
    display: flex;
    flex-direction: column;
    width: 260px;
    height: 300px;
    justify-content: center;
    position: absolute;
    top: calc(50% - 150px);
    left: calc(50% - 130px);
    border: 2px dashed #777;
    font-size: 50px
  }

  /* ------------แก้ไขใหม่--------------- */
  .headnew {
    display: flex;
    padding-top: 2rem;
    border-bottom: 1px solid rgb(0, 0, 0);
  }

  .bt-headnew>div {

    justify-content: center;
    cursor: pointer;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    font-size: 18px;
    font-weight: 600;
  }

  .active .bt-headproduct {
    color: rgb(249, 0, 1);
  }

  .bt-headproduct>div>div:hover {
    color: rgb(249, 0, 1);
  }

  .trash {
    padding: 0rem;
  }

  .function-product {
    display: flex;
    justify-content: space-around;
    border-color: transparent !important;
  }

  .function-product>div>a {
    color: black !important;
  }

  .function-product div>a:hover {
    color: rgb(249, 0, 1) !important;
  }

  .rcornersaddproduct {

    color: white;
    border-radius: 30px 30px 30px 30px;
    background-image: linear-gradient(#070707, #4b4b4b);
    padding: 4px;
    width: 100px;
    height: 35px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;


  }

  .rcornersaddproduct:hover {
    background-image: linear-gradient(#e70014, #e70014);
    color: rgb(0, 0, 0);
  }

  /* ---.ใส่ชื่อรุ่นรถ--- */
  .name_car {
    padding-Top: 2rem;
    padding-bottom: 2rem;
    padding-Left: 15rem;
    padding-Right: 15rem;

  }

  /* ---เลือกแบนเนอร์-- */
  .select_banner {
    margin: auto;
    width: 1000px;
    height: 400px;
    background-Color: #eee;
    position: relative;

  }

  .select_banner>label>div>img {
    width: 200px;
    height: 200px;
    margin: auto;

  }

  /* ลบแบนเนอร์*/
  .delete_banner {

    padding-Left: 15rem;
    padding-Right: 15rem;
    justify-Content: center;
    display: flex;
  }

  /* ใส่ราคา */
  .price {
    padding-Top: 2rem;
    padding-Left: 15rem;
    padding-Right: 15rem;

  }

  .price>div>div>label {
    font-Size: 16px;
    font-Weight: bold;

  }

  /* หลังเพิ่มรถรุ่นย่อย */
  .sub_model {
    margin: 1rem 0 1rem 0;
  }

  .sub_model>div {
    margin: auto;
    width: 350px;
    height: 430px;
    background-Color: #eee;
    position: relative;
    justify-Content: center;
    display: flex;
  }

  .sub_model>div>div {
    margin: auto;
    justify-Content: center;
    display: flex;
  }

  .sub_model>div>div>img {
    width: 100%;
    height: auto;
    position: absolute;
    top: 2rem;
    padding: 0 1rem;
    object-fit: contain;
    object-position: center;
  }

  .sub_model>div>form {
    padding-Top: 2rem;
    padding-Left: 1rem;
    padding-Right: 1rem;
    position: absolute;
    bottom: 3rem;
  }

  .sub_model>div>form>div>label {
    font-Size: 16px;
    font-Weight: bold;
  }

  /* ก่อนกดปุ่มเพิ่มรุ่นรถย่อย */
  .sub_model1 {
    margin: 1rem 0 1rem 0;
  }

  .sub_model1>input {
    display: none;
    margin: 1rem 0 1rem 0;
  }

  .sub_model1>div {
    margin: auto;
    width: 350px;
    height: 430px;
    background-Color: #eee;
    position: relative;
    justify-Content: center;
    display: flex;
  }

  .sub_model1>div>div:nth-child(1) {
    margin: auto;
    width: 350px;
    height: 180px;
    background-Color: #eee;
    position: absolute;
  }

  .sub_model1>div>div:nth-child(1)>label>div {
    cursor: pointer;
  }

  .sub_model1>div>div:nth-child(1)>label>div>img {
    width: 100px;
    height: 100px;
    margin: auto;
  }

  .sub_model1>div>div:nth-child(2) {
    margin: auto;
    justify-Content: center;
    display: flex;
  }

  .sub_model1>div>div:nth-child(2)>div {
    width: 100%;
    position: absolute;
    top: 2rem;
    padding: 0 2rem;
  }

  .sub_model1>div>div:nth-child(3) {
    padding-Left: 1rem;
    padding-Right: 1rem;
    justify-Content: center;
    display: flex;
    position: absolute;
    top: 14rem;
  }

  /* ---ฟอร์มรายละเอียดรถ--- */
  .bgDesCar {
    // margin: 0 3rem;
    width: 1200px;
    height: 250px;
    background-Color: #eee;
    position: relative;
  }

  .formDesCar {
    padding-Top: 2rem;
    padding-Left: 2rem;
    padding-Right: 1rem;
  }

  .formDesCar>div:nth-child(1)>label {
    font-Size: 16px;
    font-Weight: bold;
  }

  .formDesCar>div:nth-child(2)>label {
    font-Size: 16px;
    font-Weight: bold;
  }

  /* ---เลือกรูปรถรุ่นย่อย--- */
  .selectDesCar {
    margin: auto;
    height: 180px;
    position: relative;
  }

  .selectDesCar>label>div {
    cursor: pointer
  }

  .selectDesCar>label>div>img {
    width: 100px;
    height: 100px;
    margin: auto;
  }

  /* ---หลังเพิ่มรายละเอียดรถ--- */
  .afterAddDesCar {
    margin-Top: 1rem;
    justify-Content: center;
    display: flex;
  }

  .afterAddDesCar>img {
    width: 100%;
    padding: 0 1rem;
    height: 12rem;
    object-fit: contain;
    object-position: center;
  }

  /* ---ก่อนเพิ่มรายละเอียดรถ--- */
  .beforeAddDesCar {
    margin-Top: 1rem;
    justify-Content: center;
    display: flex;

  }

  .beforeAddDesCar>img {
    width: 100%;
    padding: 0 1rem;
    height: 12rem;
    object-fit: contain;
    object-position: center;
  }

  /* ---ถังขยะ--- */
  .styleTarsh {
    padding-Top: 1rem;
    padding-Left: 1rem;
    padding-Right: 1rem;
    justify-Content: center;
    display: flex;
  }

  .styleTarsh>svg {
    position: absolute;
    bottom: 0rem;
    cursor: pointer;
    height: 1.6rem;
    width: 1.6rem;
  }

  /* ---ปุ่มกดบันทึก---- */
  .styleButton {
    padding-Left: 15rem;
    padding-Right: 15rem;
    justify-Content: center;
    display: flex;
  }

  .rcornersnewsAdminProduct {
    color: white;
    border-radius: 30px 30px 30px 30px;
    background-image: linear-gradient(#070707, #4b4b4b);
    padding: 4px;
    width: 130px;
    height: 35px;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }

  .rcornersnewsAdminProduct:hover {
    background-image: linear-gradient(#fafafa, #818181);
    color: rgb(0, 0, 0);
  }

  @media screen and (max-width: 768px) {
    .name_car {
      padding-Top: 2rem;
      padding-bottom: 2rem;
      padding-Left: 2rem;
      padding-Right: 2rem;

    }

  }

  @media screen and (max-width: 1024px) {
    .name_car {
      padding-Top: 2rem;
      padding-bottom: 2rem;
      padding-Left: 2rem;
      padding-Right: 2rem;

    }
  }