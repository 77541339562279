@import "./Color.scss";


.testdrive {
  background-color: $black-color;

  &-content {
    // width: 49%;
    // padding-right: 15px;
    margin: 0 15% 0 15%;
    padding: 3rem;
    background-color: #fff;


    &-title {
      font-size: 1.2rem;
      font-weight: 500;
    }

    strong {
      font-weight: 500;
    }
  }
}


.buttonTest {
  background-image: linear-gradient(rgb(100, 100, 100), rgb(50, 50, 50));
  color: rgb(255, 255, 255);
  padding: 5px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  display: inline-table;
  display: inline-block;
  font-size: 19px;
  cursor: pointer;
  border-radius: 5px;
}

.buttonTest:hover {
  background-image: linear-gradient(#fafafa, #818181);
  color: rgb(0, 0, 0);
}

.container-body-testdrive {
  margin: 0 15% 4rem 15%;
  padding: 3rem;
  background-color: #fff;
}

@media screen and (max-width: 580px) {
  .testdrive {
    background-color: $black-color;

    &-content {
      // width: 49%;
      // padding-right: 15px;
      margin: 0 10% 0 10%;
      padding: 1rem;
      background-color: #fff;


      &-title {
        font-size: 1.2rem;
        font-weight: 500;
      }

      strong {
        font-weight: 500;
      }
    }
  }
}

// @media screen and (min-width: 576.1px) {}

// @media screen and (min-width: 768.1px) {}

@media screen and (max-width: 1080px) {
  .testdrive {
    background-color: $black-color;

    &-content {
      // width: 49%;
      // padding-right: 15px;
      margin: 0 5% 0 5%;
      padding: 1rem;
      background-color: #fff;


      &-title {
        font-size: 1.2rem;
        font-weight: 500;
      }

      strong {
        font-weight: 500;
      }
    }
  }
}

// @media screen and (min-width: 992.1px) {

// }