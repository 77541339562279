// import
@import "./Color.scss";

.service {
  &-content {
    &-title {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin: auto;
      margin-bottom: 30px;

      &>span {
        display: block;
        width: fit-content;
        margin: 0 1rem;
      }
    }

    &-detail {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      width: fit-content;
      margin: auto;
      //   &:nth-of-type(2n){
      //       flex-direction: column-reverse;
      //   }
    }

    &-list,
    &-list-border {
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 400px;
      height: 300px;
      color: $white-color;
      background-color: $black-color;

      &-title {
        position: relative;
        font-size: 1.4rem;
        font-weight: 700;
        height: 3.5rem;

        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          left: calc(50% - 30px);
          width: 60px;
          height: 3px;
          background-color: $white-color;
        }
      }

      img {
        object-fit: cover;
      }

      p {
        text-align: center;
        line-height: 2.5rem;
      }
    }

    &-list-border {
      box-sizing: border-box;
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      border: 10px solid $black-color;
      color: $black-color;
      background-color: $white-color;

      &>div {
        &::after {
          background-color: $black-color;
        }
      }
    }
  }

  &-center {
    .card-type {
      &-1 {
        display: flex;
        flex-wrap: wrap;
        width: 1000px;
        height: 300px;
        overflow: hidden;
        margin: 50px auto;

        &-img {
          display: block;
          width: 50%;
        }

        &-content {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          width: 50%;
          height: 100%;
          color: $white-color;
          padding: 30px 50px;
          background-color: $black-color;
        }
      }

      &-2 {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        width: 1000px;
        height: 350px;
        overflow: hidden;
        margin: 50px auto;

        &-img {
          display: block;
          width: 50%;
          z-index: -2;
        }

        &-content {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 50%;
          height: 100%;
          color: $black-color;
          font-size: 1.2rem;
          padding: 50px;
          overflow: hidden;

          &-title {
            font-size: 1.5rem;
            color: $red-color;
          }

          &::before {
            content: "";
            position: absolute;
            top: 0;
            left: calc(50% - 85px);
            display: block;
            width: 65%;
            height: 100%;
            background-image: linear-gradient(90deg, #d5d5d5, #ffffff);
            transform: skew(-25deg);
            z-index: -1;
          }
        }
      }
    }
  }
}

.paint {
  &-header {
    display: block;
    width: 1000px;
    margin: auto;

    strong {
      font-size: 1.2rem;
      color: $red-color;
    }
  }

  &-service,
  &-lounge {
    display: block;
    width: 1000px;
    margin: 50px auto;

    &-title {
      position: relative;
      display: block;
      font-size: 1.4rem;
      color: $red-color;
      margin-bottom: 1rem;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        top: 1.075rem;
        left: 135px;
        display: block;
        width: 100%;
        height: 2px;
        background-color: $red-color;
      }
    }

    &-title2 {
      position: relative;
      display: block;
      font-size: 1.4rem;
      color: $red-color;
      margin-bottom: 1rem;
      overflow: hidden;

      &::after {
        content: "";
        position: absolute;
        top: 1.075rem;
        left: 370px;
        display: block;
        width: 100%;
        height: 2px;
        background-color: $red-color;
      }
    }

    p {
      padding-left: 1rem;
    }
  }

  &-lounge {
    &-title::after {
      left: 165px;
    }

    &-title2::after {
      left: 350px;
    }
  }



  &-blackground {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    opacity: 0.1;
    z-index: -1;
  }
}

@media screen and (max-width: 1480px) {}

@media screen and (max-width: 1080px) {
  .service {
    &-content {
      &-title {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: auto;
        margin-bottom: 30px;

        &>span {
          display: block;
          width: fit-content;
          margin: 0 1rem;
        }
      }

      &-detail {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
        margin: auto;
        //   &:nth-of-type(2n){
        //       flex-direction: column-reverse;
        //   }
      }

      &-list,
      &-list-border {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 400px;
        height: 300px;
        color: $white-color;
        background-color: $black-color;

        &-title {
          position: relative;
          font-size: 1.4rem;
          font-weight: 700;
          height: 3.5rem;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: calc(50% - 30px);
            width: 60px;
            height: 3px;
            background-color: $white-color;
          }
        }

        img {
          object-fit: cover;
        }

        p {
          text-align: center;
          line-height: 2.5rem;
        }
      }

      &-list-border {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 10px solid $black-color;
        color: $black-color;
        background-color: $white-color;

        &>div {
          &::after {
            background-color: $black-color;
          }
        }
      }
    }

    &-center {
      .card-type {
        &-1 {
          display: flex;
          flex-wrap: wrap;
          width: 1000px;
          height: 300px;
          overflow: hidden;
          margin: 50px auto;

          &-img {
            display: block;
            width: 50%;
          }

          &-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: 50%;
            height: 100%;
            color: $white-color;
            padding: 30px 50px;
            background-color: $black-color;
          }
        }

        &-2 {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          width: 1000px;
          height: 350px;
          overflow: hidden;
          margin: 50px auto;

          &-img {
            display: block;
            width: 50%;
            z-index: -2;
          }

          &-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 50%;
            height: 100%;
            color: $black-color;
            font-size: 1.2rem;
            padding: 50px;
            overflow: hidden;

            &-title {
              font-size: 1.5rem;
              color: $red-color;
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: calc(50% - 85px);
              display: block;
              width: 65%;
              height: 100%;
              background-image: linear-gradient(90deg, #d5d5d5, #ffffff);
              transform: skew(-25deg);
              z-index: -1;
            }
          }
        }
      }
    }
  }

  .paint-header {
    display: block;
    width: auto;
    margin: 10px;
  }

  .paint-service,
  .paint-lounge {
    display: block;
    width: auto;
    margin: 50px auto;
  }

  .paint-service-title,
  .paint-lounge-title {
    position: relative;
    display: block;
    font-size: 1.4rem;
    color: #e70014;
    margin-bottom: 1rem;
    margin: 0 10px;
    overflow: hidden;
  }

  .paint-lounge-title2 {
    position: relative;
    display: block;
    font-size: 1.4rem;
    color: #e70014;
    margin-bottom: 1rem;
    margin: 0 10px;
    overflow: hidden;
  }

  .paint-header strong {
    font-size: 1.1rem;
    display: block;
    color: #e70014;
  }

  .paint-lounge-img {
    margin: 0 10px;
  }

}

@media screen and (max-width: 580px) {
  .service {
    &-content {
      &-title {
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        margin: auto;
        margin-bottom: 30px;

        &>span {
          display: block;
          width: fit-content;
          margin: 0 1rem;
        }
      }

      &-detail {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        width: fit-content;
        margin: auto;
        //   &:nth-of-type(2n){
        //       flex-direction: column-reverse;
        //   }
      }

      &-list,
      &-list-border {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 400px;
        height: 300px;
        color: $white-color;
        background-color: $black-color;

        &-title {
          position: relative;
          font-size: 1.4rem;
          font-weight: 700;
          height: 3.5rem;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: calc(50% - 30px);
            width: 60px;
            height: 3px;
            background-color: $white-color;
          }
        }

        img {
          object-fit: cover;
        }

        p {
          text-align: center;
          line-height: 2.5rem;
        }
      }

      &-list-border {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        border: 10px solid $black-color;
        color: $black-color;
        background-color: $white-color;

        &>div {
          &::after {
            background-color: $black-color;
          }
        }
      }
    }

    &-center {
      .card-type {
        &-1 {
          display: flex;
          flex-wrap: wrap;
          width: auto;
          height: auto;
          overflow: hidden;
          margin: 50px auto;

          &-img {
            display: block;
            width: auto;
            margin: 0 10px;
          }

          &-content {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-wrap: wrap;
            width: auto;
            height: 100%;
            color: $white-color;
            padding: 30px 50px;
            background-color: $black-color;
            margin: 0 10px;
          }
        }

        &-2 {
          position: relative;
          display: flex;
          flex-wrap: wrap;
          width: auto;
          height: auto;
          overflow: hidden;
          margin: 50px auto;

          &-img {
            display: block;
            width: 100%;
            z-index: 1;
            padding: 0 10px 0 10px;
          }

          &-content {
            display: flex;
            align-items: center;
            justify-content: center;
            width: auto;
            height: 100%;
            color: $black-color;
            font-size: 0.8rem;
            padding: 10px 65px;
            overflow: hidden;

            &-title {
              font-size: 1rem;
              color: $red-color;
            }

            &::before {
              content: "";
              position: absolute;
              top: 0;
              left: calc(50% - 85px);
              display: block;
              width: 65%;
              height: 100%;
              background-image: linear-gradient(90deg, #d5d5d5, #ffffff);
              transform: skew(-25deg);
              z-index: -1;
            }
          }
        }
      }
    }
  }

  .paint-header {
    display: block;
    width: auto;
    margin: 10px;
  }

  .paint-service,
  .paint-lounge {
    display: block;
    width: auto;
    margin: 50px auto;
  }

  .paint-service-title,
  .paint-lounge-title {
    position: relative;
    display: block;
    font-size: 1.4rem;
    color: #e70014;
    margin-bottom: 1rem;
    margin: 0 10px;
    overflow: hidden;
  }

  .paint-header strong {
    font-size: 1.1rem;
    display: block;
    color: #e70014;
  }

  .paint-lounge-img {
    margin: 0 10px;
  }


}