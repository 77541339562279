// import
@import "./Color.scss";

// ------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------
// AppAdmin.js
// ------------------------------------------------------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------------------------------------------------------
.mobilesize {
  padding-top: 100px;
  width: 100vw;
  height: 100vh;
  //backgroundColor: "black",
  justify-content: center;

  .danger {

    background-color: white;
    border-radius: 20px;
    height: 18rem;
    width: 70vw;
    margin: auto;
    padding: 2rem;
    font-size: 20px;
    font-weight: bold;
    justify-content: center;

  }
}

.AppAdmin {
  .sidebar-admin {
    position: fixed;
    top: 0;
    bottom: 0;
    display: block;
    width: 250px;
    height: 100vh;
    background-color: $black-color;
    color: $main-text-color;

    &>div {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 10px;
      font-size: 1.2rem;

      img {
        object-fit: contain;
        width: 30px;
        height: 30px;
        margin-right: 10px;
        margin-left: 20px;
      }
    }

    &>span {
      display: flex;
      width: 100%;
      padding: 10px;
      font-size: 1.1rem;
    }

    &>a {
      display: flex;
      padding: 10px 20px;
      color: $main-text-color;

      &:hover {
        background-color: $red-color;
      }
    }

    &>a.active {
      background-color: $red-color;
    }
  }

  .set-page-admin {
    width: calc(100% - 250px);
    margin-left: 250px;

    .admin-navbar {
      width: 100%;
      height: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 20px;
      background-color: $black-color;
      color: $main-text-color;

      a {
        color: $main-text-color;
      }
    }

    .element-body {
      width: 100%;
      padding: 10px 30px;
    }
  }
}



.tab {
  width: 100%;
  // margin-top: 1rem;
  // color: $main-color;

  .nav-link.active {
    background-color: $red-color;
    color: $main-text-color;
    border-color: #dee2e6 #dee2e6 #fff;
  }

  a {
    color: $black-color;
    font-weight: 400;
    text-align: center;
  }
}

.rcornerscontact {
  width: fit-content;
  height: auto;
  background: black;
  -webkit-transform: skew(-20deg);
  transform: skew(-20deg);
  border-radius: 5px;
  padding: 1.5vh 1.5vw;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5vw;
  font-weight: 500;
  margin-bottom: 1rem;
  color: $red-color;
  /* color: #fff; */
}

// .content{
//   width: 100%;
// }

// advPage













//---------------------------------------------------topupadmin--------------------------------------------------
//---------------------------------------------------------------------------------------------------------------