.rcornerscarAdmin {
  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 4px;
  width: 80px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornerscarAdmin:hover {
  background-image: linear-gradient(#fafafa, #e70014);
  color: rgb(0, 0, 0);
}

.rcornerscarAdmin1 {
  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 4px;
  width: 280px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornerscarAdmin1:hover {
  background-image: linear-gradient(#fafafa, #818181);
  color: rgb(0, 0, 0);
}

.rcornersnewsAdminCar {
  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 4px;
  width: 130px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornersnewsAdminCar:hover {
  background-image: linear-gradient(#fafafa, #e70014);
  color: rgb(0, 0, 0);
}

.trash {
  padding: 0rem !important;
}

.function-car {
  display: flex;
  align-items: center;
  justify-content: space-around;
  border-color: transparent !important;
}

.function-car>div>a {
  color: black !important;
}

.function-car div>a:hover {
  color: rgb(249, 0, 1) !important;
}

.car {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 15rem;
  padding-right: 15rem;
}

.carpic {
  width: "50rem";
  height: "400px";
  background-color: "#eee";
  position: "relative";
}

.rcornerscar1 {

  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#fafafa, #e70014);
  padding: 6px;
  width: 160px;
  /* height: 35px; */
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 1rem;

}

.rcornerscar1:hover {
  background-image: linear-gradient(#fafafa, #60ca3f);
  color: rgb(0, 0, 0);
}

@media screen and (max-width: 768px) {
  .car {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 1024px) {
  .car {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}