.rcornersbanner {
    width: fit-content;
    height: auto;
    background: black;
    -webkit-transform: skew(-20deg);
    transform: skew(-20deg);
    border-radius: 5px;
    padding: 1.5vh 1.5vw;
    display: flex;
    align-items: center;
    justify-content: center;
    /* font-size: 1.5vw; */
    /* font-weight: 500; */
    /* color: #fff; */
  }
  .function-product {
    display: flex;
    justify-content: space-around;
    border-color: transparent !important;
  }

  .function-product>div>a {
    color: black !important;
  }

  .function-product div>a:hover {
    color: rgb(249, 0, 1) !important;
  }
  .border-banner{
      display: flex;
      flex-direction: column;
      width: 250px;
      height: 250px;
      justify-content:center;
      position: absolute;
      top: calc(50% - 125px);
      left: calc(50% - 125px);
      border: 2px dashed #777;
  }
  .rcornersbanner1 {
    
    color: white;
    border-radius: 30px 30px 30px 30px;
    background-image: linear-gradient(#070707,#4b4b4b);
    padding: 6px; 
    width: 240px;
    /* height: 35px; */
    text-align: center;
    font-size: 20px;
    cursor: pointer;
    margin-bottom: 1rem;
   
  }
  .rcornersbanner1:hover {
    background-image: linear-gradient(#fafafa,#818181);
    color: rgb(0, 0, 0);
  }
  .function-banner{
    color: black;
    cursor: pointer;
  }
  .function-banner:hover{
    color: rgb(249, 0, 1);
  }