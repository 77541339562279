$white-color: #fff;
$black-color: #000;
$gray-color: #808080;
$blue-color: #007bff;
$red-color: #e70014;
$gray-color2: #aaa;
$green-color2: #60ca3f;

$background: #fff;
$main-text-color: #fff;
$text-userinfo-gray: #aaa;
$text-gray: #505050;