.banner-admin {
    background-color: rgb(255, 255, 255);
    height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;

}

.BoxLogin {
    width: 50vw;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: normal;
    justify-content: normal;
    border: none;
    border-radius: 1vw;
    padding: 3vh 3vw;
    background-color: #fff;
    box-shadow: 0 2px 22px rgba(0, 0, 0, 0.6);

}



.Fontadmin {
    text-align: center
}
.mobilesize {
    padding-top: 100px;
    width: 100vw;
    height: 100vh;
    //backgroundColor: "black",
    justify-content: center;
  
    .danger {
  
      background-color: white;
      border-radius: 20px;
      height: 18rem;
      width: 70vw;
      margin: auto;
      padding: 2rem;
      font-size: 20px;
      font-weight: bold;
      justify-content: center;
  
    }
  }
  