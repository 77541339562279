//
@import "./Color.scss";

//
.home {
  &-banner {
    min-height: 50vh;
    //height: 50vh;
    background-color: #000000;
    overflow: hidden;

    .carousel {
      //height: 100%;
      //max-height: 468px;

      &-item,
      &-inner {
        height: 100%;
      }

      .fas {
        font-size: 3rem;
        color: $red-color;
      }
    }
    &.mobile {
      display: none;
    }
  }

  &-service {
    display: grid;
    grid-template-columns: 350px 350px 350px;
    width: fit-content;
    margin: 30px auto;

    &-list {
      display: grid;
      grid-template-rows: 200px 200px;
      background-color: $black-color;
      border: 30px solid $black-color;
      background-color: $white-color;

      & + .white {
        background-color: $white-color;
        border: 30px solid $white-color;
      }

      & + .black {
        color: $white-color;
        background-color: $black-color;

        & > div > strong::after {
          background-color: $white-color;
        }
      }

      & > div {
        display: block;
        text-align: center;

        i {
          font-size: 3rem;
          margin-top: 1.5rem;
        }

        strong {
          position: relative;
          font-size: 1.2rem;
          font-weight: 500;
          line-height: 2rem;

          &::after {
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $black-color;
          }
        }
      }
    }

    img {
      object-fit: cover;
    }
  }

  &-content {
    &-title {
      text-align: center;
      margin: 50px auto;

      strong {
        font-size: 2.5rem;
        font-weight: 500;
      }
    }

    &-slide {
      position: relative;
      width: calc(1400px - 40px);
      min-height: 350px;
      margin: auto;
      margin-bottom: 50px;

      &::before {
        content: "";
        display: block;
        width: 80%;
        height: 200px;
        background-color: $black-color;
        margin: auto;
        margin-bottom: -100px;
      }

      &.plus-100 {
        &::before {
          content: "";
          height: 300px;
          margin-bottom: -180px;
        }
      }

      &-title {
        position: absolute;
        top: 25px;
        display: block;
        width: 100%;
        text-align: center;
        color: $white-color;

        strong {
          font-size: 1.5rem;
          font-weight: 500;
          line-height: 3.5rem;
        }
      }

      .slide {
        &-car,
        &-promotion {
          text-align: center;
          padding: 20px 50px;

          &-thumb {
            width: 320px;
            margin: auto;
            cursor: pointer;

            &-img {
              height: 145px;
              overflow: hidden;

              img {
                object-fit: cover;
                object-position: center;
                transition: all 0.5s;
              }
            }

            &-content {
              display: block;
              margin-top: 15px;

              strong {
                display: block;
                font-size: 1.2rem;
                font-weight: 500;
                white-space: pre;
                text-overflow: ellipsis;
                overflow: hidden;
              }
            }

            &:hover {
              img {
                width: calc(100% + 20px);
                height: calc(100% + 20px);
              }
            }
          }
        }

        &-car-thumb {
          width: 240px;
        }

        &-promotion {
          padding: 20px 10px;

          &-thumb {
            width: 320px;
            border-radius: 2px;
            margin: auto;
            overflow: hidden;

            &-img {
              height: 256px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1480px) {
  .home {
    &-content {
      &-slide {
        width: calc(100% - 150px);
      }
    }
  }
}

@media screen and (max-width: 1080px) {
  .home {
    &-content {
      &-slide {
        width: calc(100% - 100px);
      }
    }

    &-banner {
      min-height: 20vh;
      height: 25vh;
      background-color: #000000;
      overflow: hidden;

      .carousel {
        //height: 100%;
        max-height: 468px;

        &-item,
        &-inner {
          height: 100%;
        }

        .fas {
          font-size: 3rem;
          color: $red-color;
        }
      }
    }
  }
}

@media screen and (max-width: 580px) {
  .home {
    // color: $white-color;
    color: $black-color;
    padding-bottom: 1px;
    border-bottom: 1px solid $red-color;

    // background-color: $black-color;
    &-content {
      &-slide {
        width: calc(100% - 60px);
        max-width: 320px;
        margin-bottom: 0;

        &::before {
          width: 100%;
          background-color: transparent;
        }

        &-title {
          color: $black-color;
        }

        .slide {
          &-car {
            padding: 0;

            &-thumb {
              width: 240px;
            }
          }

          &-promotion {
            padding: 20px 0;

            &-thumb {
              width: 240px;

              &-img {
                height: 180px;
              }
            }
          }
        }
      }
    }

    &-banner {
      min-height: 300px;
      background-color: #000000;
      // height: 15vh;
      // overflow: hidden;

      .carousel {
        width: 100%;
        // max-height: 468px;

        &-item,
        &-inner {
          height: 100%;
        }

        .fas {
          font-size: 3rem;
          color: $red-color;
        }
      }
      &.desktop {
        display: none;
      }
      &.mobile {
        display: flex;
        width: 100%;
        height: fit-content;
      }
    }

    // .slick-next:before,
    // .slick-prev:before {
    //   color: $white-color;
    // }
  }
}
