.rcornersnewsAdmin {
  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 4px;
  width: 80px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornersnewsAdmin:hover {
  background-image: linear-gradient(#fafafa, #818181);
  color: rgb(0, 0, 0);
}

.rcornersnewsAdmin1 {
  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 4px;
  width: 280px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornersnewsAdmin1:hover {
  background-image: linear-gradient(#fafafa, #818181);
  color: rgb(0, 0, 0);
}

.rcornersnewsAdmin2 {
  color: white;
  border-radius: 30px 30px 30px 30px;
  background-color: red;
  padding: 4px;
  width: 280px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornersnewsAdmin2:hover {
  background-image: linear-gradient(#fafafa, #818181);
  color: rgb(0, 0, 0);
}

.rcornersnewsAdmin3 {
  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 4px;
  width: 130px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornersnewsAdmin3:hover {
  background-image: linear-gradient(#fafafa, #818181);
  color: rgb(0, 0, 0);
}

.rcornersnew1 {

  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 6px;
  width: 160px;
  /* height: 35px; */
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 1rem;

}

/* ------------แก้ไขใหม่--------------- */
.headnew {
  display: flex;
  padding-top: 2rem;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.bt-headnew>div {
  justify-content: center;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  font-size: 18px;
  font-weight: 600;
}

.active .bt-headnew {
  color: rgb(249, 0, 1);
}

.bt-headnew>div>div:hover {
  color: rgb(249, 0, 1);
}

.trash {
  padding: 0rem !important;

}

.function-new {
  display: flex;
  justify-content: space-around;
  border-color: transparent !important;
}

.function-new>div>a {
  color: black !important;
}

.function-new div>a:hover {
  color: rgb(249, 0, 1) !important;
}

.BackgroundMob {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(1, 114, 187);
  margin: 0 0 0 0;
}

.DivErrorMob {
  /* width: 100%;
  height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 10vh 10vw 10vh 10vw;
  background-color: #fff;
  border: none;
  border-radius: 25px;
  margin: 0 15vw 0 15vw;
}

.LabelErrorMob {
  width: 100%;
  text-align: center;
  color: #000;
  font-size: 2.5vh;
  margin: 3vh 0 0 0;
}

.admin_news {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 15rem;
  padding-right: 15rem;
}

.fontNew {
  font-Size: 16px;
  font-Weight: bold;
}

/* text edit */
.rdw-editor-main {
  padding: 0 10px;
}

.public-DraftStyleDefault-block {
  margin: 0.25rem 0 !important;
}

@media screen and (max-width: 768px) {
  .admin_news {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .fontNew {
    font-Size: 13px !important;
    font-Weight: bold;
  }
}

@media screen and (max-width: 1024px) {
  .admin_news {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

}