//
@import "./Color.scss";

//
.news {
    justify-content: center;
    max-width: 1700px;
    margin-left: auto !important;
    margin-right: auto !important;

    &-thumb {
        width: 400px;
        margin: 30px;
        text-align: center;
        color: $white-color;
        margin: 1rem auto;

        &-img {
            width: 100%;
            height: 300px;
            background-color: $black-color;
            min-height: 300px;
            cursor: pointer;
            overflow: hidden;

            img {
                transition: all 0.5s;
            }

            &:hover {
                img {
                    width: calc(100% + 20px);
                    height: calc(100% + 20px);
                    object-position: center;
                }
            }
        }

        &-content {
            padding-top: 1rem;
            background-color: $black-color;
            min-height: 11rem;
            position: relative;

            strong {
                font-size: 1.199rem;
                font-weight: 400;
            }

            button {
                position: absolute;
                right: 0;
                bottom: 0;
                color: $red-color;
                margin-right: 1rem;
                margin-bottom: 1rem;

                &:hover {
                    color: $white-color;
                }
            }
        }
    }
}

.news_dt {
    display: block;
    width: 70% !important;
    padding: 30px !important;
    background-color: $white-color;

    &-title {
        width: 100%;
        font-size: 2rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    &-img {
        margin-bottom: 3rem;
    }
}

@media screen and (max-width: 1480px) {
    .news {
        &-thumb {
            width: 350px;

            &-img {
                height: 280px;
            }
        }
    }
}

@media screen and (max-width: 1080px) {
    .news_dt {
        display: block;
        width: 100% !important;
        padding: 30px !important;
        background-color: $white-color;

        &-title {
            width: 100%;
            font-size: 2rem;
            text-align: center;
            margin-bottom: 1rem;
        }

        &-img {
            margin-bottom: 3rem;
        }
    }

    .news {
        &-thumb {
            width: 98%;

            &-img {
                height: 300px;

                &:hover {
                    img {
                        width: 100%;
                        height: 100%;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 580px) {
    .news {
        padding: 0 !important;

        &-thumb {
            width: 100%;
        }
    }

    .news_dt-title {
        width: 100%;
        font-size: 1.5rem;
        text-align: center;
        margin-bottom: 1rem;
    }

    .news_dt {
        display: block;
        width: 100% !important;
        padding: 30px !important;
        background-color: #fff;
    }
}