// import
@import "./Color.scss";
@import url("https://fonts.googleapis.com/css?family=Prompt:400,500,600,700&display=swap");

// screen size
// @media screen and (min-width: 1680px)
// @media screen and (max-width: 1680px)
// @media screen and (max-width: 1480px)
// @media screen and (max-width: 1280px)
// @media screen and (max-width: 1080px)
// @media screen and (max-width: 780px)
// @media screen and (max-width: 580px)

// style
body {
  font-family: "Prompt", sans-serif;
  font-size: 16px !important;
  font-weight: 400;
  background-color: $background;
  margin: 0;
}

img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

.slick-next:before,
.slick-prev:before {
  color: $black-color;
}

.header {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  height: 190px;
  color: $white-color;
  background-color: $black-color;

  &-title {
    position: relative;
    width: fit-content;
    height: 75px;
    color: inherit;
    font-size: 2.8rem;
    font-weight: 500;
    margin-top: calc(95px - 38px);

    &::after {
      content: "";
      position: absolute;
      left: 20%;
      right: 20%;
      bottom: 0;
      display: flex;
      width: 60%;
      height: 4px;
      background-color: $white-color;
    }
  }

  &-sub_title {
    display: block;
    width: 100%;
    text-align: center;
    font-size: 1.2rem;
  }

  &-25 {
    height: 330px;

    & + div {
      width: fit-content;
      margin: auto;
      margin-top: -140px;
      padding: 0 30px;
    }
  }

  &-50 {
    height: calc(75vh - 112px);

    & + div {
      width: fit-content;
      margin: auto;
      margin-top: calc(-75vh + 112px + 190px);
      padding: 0 30px;
    }
  }

  &-75 {
    height: calc(100vh - 112px);

    & + div {
      width: fit-content;
      margin: auto;
      margin-top: calc(-100vh + 112px + 190px);
      padding: 0 30px;
    }
  }

  &-white,
  &-transparent {
    color: $black-color;
    background-color: $white-color;

    .header-title {
      &::after {
        background-color: $black-color;
      }
    }
  }

  &-transparent {
    background-color: transparent;
  }
}

.app-container {
  min-height: calc(100vh - 202px);

  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 112px;
    background-color: $black-color;
    background-image: linear-gradient(to right, $black-color 70%, $gray-color 100%);
  }
}

.social {
  &-list {
    position: fixed;
    top: 40vh;
    right: 5px;
    display: block;
    z-index: 99;
    >div{
      display: block;
      width: fit-content;
      height: 60px;
      border: 1px solid transparent;
    }
  }
  &-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 45px;
    height: 45px;
    margin-top: 15px;
    font-size: 1.5rem;
    border-radius: 50%;
    transition: all 0.3s;
    &.facebook,
    &.youtube,
    &.line,
    &.phone {
      color: #fff;
      text-decoration: unset;
    }
    &.facebook {
      background-color: #3e68c0;
    }
    &.youtube {
      font-size: 1.4rem;
      background-color: #ee3130;
    }
    &.line {
      background-color: #00c300;
    }
    &.phone {
      font-size: 1.2rem;
      background-color: #33cc49;
    }
    &:hover {
      margin-top: 5px;
      background-color: #222222 !important;
    }
  }
}

@media screen and (max-width: 1480px) {
  body {
    font-size: 14px !important;
  }
}

@media screen and (min-width: 1680px) {
  @for $i from 1 through 12 {
    .col-xxl-#{$i} {
      -ms-flex: 0 0 calc(8.333333% * #{$i});
      flex: 0 0 calc(8.333333% * #{$i});
      max-width: calc(8.333333% * #{$i});
      position: relative;
      width: 100%;
      padding-right: 15px;
      padding-left: 15px;
    }
  }
}

@media screen and (max-width: 580px) {
  body {
    font-size: 14px !important;
  }

  .app-container::before {
    height: 80px;
  }

  .header-50 + div {
    padding: 0 10px;
  }

  .header-75 {
    height: calc(60vh - 200px);
  }

  .header-75 + div {
    //width: fit-content;
    margin: auto;
    //margin-top: calc(-100vh + 112px + 190px);
    padding: 30px 0 0 0;
  }

  .header-title {
    font-size: 1.8rem;
  }
}
