.rcornersPromotionAdmin {

  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 4px;
  width: 80px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornersPromotionAdmin:hover {
  background-image: linear-gradient(#fafafa, #e70014);
  color: rgb(0, 0, 0);
}

.rcornersnewsAdmin3 {
  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 4px;
  width: 130px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornersnewsAdmin3:hover {
  background-image: linear-gradient(#f90001, #e70014);
  color: rgb(0, 0, 0);
}

.rcornersPromotionAdmin1 {

  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 4px;
  width: 280px;
  height: 35px;
  text-align: center;
  font-size: 20px;
  cursor: pointer;
}

.rcornersPromotionAdmin1:hover {
  background-image: linear-gradient(#fafafa, #818181);
  color: rgb(0, 0, 0);
}

.rcornerspromo1 {

  color: white;
  border-radius: 30px 30px 30px 30px;
  background-image: linear-gradient(#070707, #4b4b4b);
  padding: 6px;
  width: 160px;
  /* height: 35px; */
  text-align: center;
  font-size: 20px;
  cursor: pointer;
  margin-bottom: 1rem;

}

/* ------------แก้ไขใหม่--------------- */
.headpromotion {
  display: flex;
  padding-top: 2rem;
  border-bottom: 1px solid rgb(0, 0, 0);
}

.bt-headpromotion>div {
  justify-content: center;
  cursor: pointer;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 1rem;
  font-size: 18px;
  font-weight: 600;
}

.active .bt-headpromotion {
  color: rgb(249, 0, 1);
}

.bt-headpromotion>div>div:hover {
  color: rgb(249, 0, 1);
}

.trash {
  padding: 0rem !important;
}

.function-promotion {
  display: flex;
  justify-content: space-around;
  border-color: transparent !important;
}

.function-promotion>div>a {
  color: black !important;
}

.function-promotion div>a:hover {
  color: rgb(249, 0, 1) !important;
}

.promo {
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 15rem;
  padding-right: 15rem;
}

.fontPromotion {
  font-Size: 16px;
  font-Weight: bold;
}

@media screen and (max-width: 768px) {
  .fontPromotion {
    font-Size: 13px !important;
    font-Weight: bold;
  }

  .promo {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }

}

@media screen and (max-width: 1024px) {
  .promo {
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}